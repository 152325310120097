/* Footer.css */

.footer-container {
    background-color: #1a1a2e;
    color: white;
    padding: 20px 0;
    text-align: center;
    font-size: 0.9rem;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-content h4 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .footer-content p {
    margin: 5px 0;
  }
  
  .footer-content a {
    color: #00a8cc;
    text-decoration: none;
  }
  
  .footer-content a:hover {
    text-decoration: underline;
  }
  