/* Home.css */

/* Container chính cho trang Home */
.h-home-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Hàng đầu tiên chia làm 2 cột với tỷ lệ 2/5 và 3/5 */
.h-first-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 400px;
    background-color: #4f90f2;
}

.h-column-left, .h-column-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.h-column-left {
    flex: 2;
}

.h-column-right {
    flex: 3;
}

.h2 {
    font-size: 1.8rem;
    color: #333333;
    margin-bottom: 20px;
    text-align: center;
}

.h-buttons-row {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
}

.h-btn {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 50px;
    background-color: #007bff;
    color: white;
    border: none;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s;
}

.h-btn:hover {
    background-color: #0056b3;
}

.h-btn-about {
    background-color: #28a745;
}

.h-btn-about:hover {
    background-color: #218838;
}

.h-btn-services {
    background-color: #007bff;
}

.h-btn-services:hover {
    background-color: #0056b3;
}

.h-representative-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.h-second-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;
    min-height: 400px;
    background-color: #f5f7fa;
    color: #007bff;
}

.h-second-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.h-second-column.h-column-left {
    flex: 2;
}

.h-second-column.h-column-right {
    flex: 3;
}

.h-mission-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

h3 {
    font-size: 1.5rem;
    color: #333333;
    margin-bottom: 10px;
}

.h-p {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.h-mission-list {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
}

/* Container cho các dịch vụ */
.h-services-container {
    margin-top: 40px;
    text-align: center;
}

.h-articles-grid {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
}

.h-article-card {
    width: 23%;
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 400px;
}

.h-article-title {
    font-size: 1.1rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
}

.h-article-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 10px;
}

.h-article-summary {
    font-size: 0.9rem;
    color: #333;
    text-align: justify;
    flex-grow: 1;
}

.h-article-link {
    margin-top: 10px;
    padding: 8px 16px;
    border-radius: 50px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s;
}

.h-article-link:hover {
    background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .h-first-row,
    .h-second-row {
        flex-direction: column;
        align-items: center;
    }

    .h-article-card {
        width: 100%;
        margin-bottom: 20px;
    }

    .h-buttons-row,
    .h-mission-list {
        text-align: center;
    }

    .h-articles-grid {
        flex-direction: column;
        align-items: center;
    }

    .h-column-left, .h-column-right, .h-second-column {
        flex: none;
        width: 100%;
    }
}
