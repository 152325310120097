/* ArticleDetail.css */

.article-detail-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    color: brown;
}

.article-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

/* Căn chỉnh ảnh và summary bằng Flexbox */
.article-header {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

/* Đặt chiều rộng cố định cho ảnh */
.article-image img {
    width: 250px !important;
    height: auto;
    border-radius: 10px;
}

/* Summary chiếm phần còn lại của dòng */
.article-summary {
    flex: 1;
    font-size: 1rem;
    color: #555;
    text-align: justify;
    margin-left: 10px;
}

/* Media query để cải thiện hiển thị trên màn hình nhỏ */
@media (max-width: 768px) {
    .article-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .article-image img {
        width: 100%; /* Đặt ảnh chiếm toàn bộ chiều rộng container */
        margin-bottom: 15px;
    }

    .article-summary {
        text-align: left;
        margin-left: 0;
    }

    .article-title {
        font-size: 1.5rem;
    }
}

/* Thêm định dạng cho nội dung bài viết */
.article-contents {
    margin-top: 20px;
}

.article-text {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
    color: #333;
}

.article-html {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
}

.article-video {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
