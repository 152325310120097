.category-articles-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
}

.category-title {
    font-size: 2rem;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.articles-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.article-item {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.article-image img {
    width: 250px;
    height: auto;
    border-radius: 5px;
}

.article-details {
    flex: 1;
}

.article-title-link h2 {
    font-size: 1.5rem;
    color: #007bff;
    margin: 0;
    cursor: pointer;
    text-decoration: none;
    text-align: left; /* Căn trái tiêu đề */
}

.article-title-link h2:hover {
    text-decoration: underline;
}

.article-date {
    font-size: 0.9rem;
    color: #888;
    margin-top: 5px;
    text-align: left; /* Căn trái ngày tháng */
}

.article-summary {
    font-size: 1rem;
    color: #333;
    margin-top: 10px;
    text-align: justify; /* Căn đều cho đoạn tóm tắt */
}

.article-detail-button {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 16px;
    border-radius: 50px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s;
    margin-left: auto;
    margin-right: auto; /* Căn giữa nút "Chi tiết" */
}

.article-button-container {
    display: flex;
    justify-content: center; /* Căn giữa nút bên trong container */
}

.article-detail-button:hover {
    background-color: #0056b3;
}

.pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.pagination button {
    padding: 8px 12px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.pagination button.active {
    background-color: #007bff;
    color: white;
    font-weight: bold;
}

.pagination button:hover {
    background-color: #e0e0e0;
}

/* Responsive adjustments for mobile screens */
@media (max-width: 768px) {
    .article-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .article-image img {
        width: 100%;
        margin-bottom: 10px;
    }

    .article-title-link h2 {
        font-size: 1.2rem;
        text-align: center;
    }

    .article-summary {
        font-size: 0.9rem;
        text-align: left;
    }

    .pagination {
        flex-wrap: wrap;
        gap: 5px;
    }

    .pagination button {
        padding: 6px 10px;
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .category-title {
        font-size: 1.5rem;
    }

    .article-title-link h2 {
        font-size: 1rem;
    }

    .article-summary {
        font-size: 0.8rem;
    }

    .pagination button {
        padding: 5px 8px;
        font-size: 0.8rem;
    }
}
