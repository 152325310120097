/* Contact.css */

/* Container chính cho trang Contact */
.contact-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: white;
    color: rgb(61, 6, 6);
  }
  
  .contact-title {
    font-size: 2rem;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .contact-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
    line-height: 1.5;
    text-align: center;
  }
  
  .contact-form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form-group {
    margin-bottom: 15px;
  }
  
  .contact-form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  .contact-form-group input,
  .contact-form-group textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 1rem;
    box-sizing: border-box;
  }
  
  .contact-form-group input:focus,
  .contact-form-group textarea:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .contact-form-group textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .contact-recaptcha {
    margin: 20px 0;
    display: flex;
    justify-content: center;
  }
  
  .contact-submit-btn {
    display: inline-block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .contact-submit-btn:hover {
    background-color: #0056b3;
  }
  
  .contact-info {
    margin-top: 20px;
    text-align: center;
  }
  
  .contact-info p {
    margin: 5px 0;
    font-size: 1rem;
    color: #333;
  }
  